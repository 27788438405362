import logo from '../assets/logo.png'
import { FaBars,FaXmark } from "react-icons/fa6";
import { useState} from 'react';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LanguageSelector from './languaje-selector';
const Navbar = () => {
    const {t} = useTranslation();
    const [isMenuOpen, setIsMenuOpen]= useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }
    const navItems = [
        {link: t('navbar.line1'), path: "/home"},
        {link: t('navbar.line2'), path: "/about"},
        {link: t('navbar.line3'), path: "/pricing"},
        {link: t('navbar.line4'), path: "/blog"},
        {link: t('navbar.line5'), path: "/contacto"},
    ]
    return(
        <>
        <nav className={'bg-white md:px-14 p-4 max-w-screen-2xl border-b mx-auto text-primary fixed top-0 right-0 left-0'}>
            <div className='text-lg container mx-auto flex justify-between items-center rubik-scribble-regular font-medium'>
                <div className='flex space-x-12 items-center'>
                    <a href="/" className='text-2xl font-semibold flex items-center space-x-3 text-primary'>
                        <img src={logo} alt="" className='w-10 inline-block items-center' /><span>MISS. Clara Spanish Academy</span>
                        </a>
                        <ul className='md:flex space-x-12 hidden'>
                            {
                                navItems.map(({link,path}) => <Link activeClass='active' spy={true} smooth={true} offset={-100} key={link} to={path} className='block hover:text-[#80F7BB] items-center hidden space-x-8 lg:flex flex cursor-pointer'>{link}</Link>)
                            }
                        </ul>
                </div>
                {/**lenguaje */}
                <LanguageSelector/>
                {/**menu btn */}
                <div className='md:hidden'>
                    <button onClick={toggleMenu} className='text-white focus:outline-none focus:text-gray-300'>
                        {
                            isMenuOpen ? (<FaXmark className='w-6 h-6 text-primary'/> ): (<FaBars className='w-6 h-6 text-primary'/>)
                        }
                    </button>
                </div>
            </div>
        </nav>
        <div className={`space-y-4 px-4 pt-24 pb-5 bg-white space-x-6 text-xl text-primary ${isMenuOpen ? "block z-50 fixed top-0 right-0 left-0": "hidden"}`}>
            {navItems.map(({link,path}) => <Link activeClass='active' spy={true} smooth={true} offset={-100} key={link} to={path} className='z-50 block hover:text-gray-300' onClick={toggleMenu}>{link}</Link>)}
        </div>
        </>
    );
};

export default Navbar;