import profile from '../assets/profile.png'
import {useTranslation} from 'react-i18next';
const Present = () =>{
  const {t} = useTranslation();
  const {line1, line2,line3,line4,line5} = t("present");
    return(
<div className="container my-24 mx-auto md:px-6 " id="about">
<h3 className="text-5xl text-white font-bold lg:2 mb-4 text-center pb-8 pt-150">{line5}</h3>
  <section className="mb-32 text-center lg:text-left">
    <div className="py-12 md:px-6 md:px-12">
      <div className="container mx-auto xl:px-32">
        <div className="flex grid items-center lg:grid-cols-2">
          <div className="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
            <div className="relative z-[1] block rounded-lg BGgd px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] md:px-12 lg:-mr-14">
              {/** }<h2 class="mb-2 text-3xl font-bold text-primary dark:text-primary-400">
                Anna Doe
              </h2>
    <p class="mb-4 font-semibold">Graphic designer</p> {*/}
              <p className="mb-6 text-2xl">
                {line1}
              </p>
              <p className="mb-6 text-2xl">
              {line2}
              </p>
              <p className="mb-6 text-2xl">
              {line3}
              </p>
              <p className="mb-6 text-2xl">
              {line4}
              </p>
            </div>
          </div>
          <div className="md:mb-12 lg:mb-0">
            <img src={profile}
              className="lg:rotate-[6deg] w-full rounded-lg shadow-lg" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
    );
};
export default Present;