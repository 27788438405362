import Navbar from '../components/navbar';
import Home from '../components/Home';
import Actividades from '../components/Actividades';
import Act2 from '../components/Act2';
import Act3 from '../components/Act3';
import Act4 from '../components/Act4';
import Features from '../components/Features';
import Footer from '../components/Footer';

const Home1 = () => {
    return (
        <>
        <Navbar/>
        <Home/>
        <Features/>
        <Actividades/>
        <Act2/>
        <Act3/>
        <Act4/>
        <Footer/>
        </>
    );
};

export default Home1;