import React from 'react';

function FeedInsta() {
  return (
    <div className='pt-20 items-center justify-items-center'>
      <div className="powr-social-feed" id="2274c6f0_1713637413"></div>
    </div>
  );
}

export default FeedInsta;
