import Footer from "../components/Footer";
import Form from "../components/form";
import Navbar from "../components/navbar";

const Contac = () => {
    return (
        <>
        <Navbar/>
        <Form/>
        <Footer/>
        </>
    );
};

export default Contac;