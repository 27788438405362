import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { MdOutlineAlternateEmail } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import {useTranslation} from 'react-i18next';
const Form = () => {
  const {t} = useTranslation();
  const {line1, line2,line3,line4,line5,line6,line7,line8,line9,line10} = t("contanc");
  const form = useRef();
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ok2bz65', 'template_pf0u75q', form.current, {
        publicKey: 'PhhdRB23fuOJ_iJMH',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsSent(true);
          setTimeout(() => {
            setIsSent(false);
          }, 5000); // Reset the "isSent" state after 5 seconds
          resetForm(); // Reset the form after sending
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  const resetForm = () => {
    form.current.reset();
  };
    return(
        <div class="container my-24 mx-auto md:px-6">
          {isSent &&
            <dialog id="alert-dialog">
  <p>Esto es un ejemplo de mensaje de diálogo.</p>
  <button onclick="this.parentElement.close()">Aceptar</button>
</dialog>}
  <section class="mb-32">
    <div class="relative h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat bg-[url('https://mdbcdn.b-cdn.net/img/new/textures/full/284.jpg')]"></div>
    <div class="container px-6 md:px-12">
      <div
        class="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px]">
        <div class="flex flex-wrap">
          <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6">
            <form ref={form} onSubmit={sendEmail}>
            <div class="relative mb-6">
                <input type="text" id="exampleInput90 " name="user_name"
                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                    placeholder="nac"/>
                <label
                for="exampleInput90"
                class="absolute text-sm text-neutral-500 transition-all duration-200 ease-out transform -translate-y-4 scale-75 top-0 left-3 origin-[0_0] peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none">
                {line1}
                </label>
                </div>
              <div class="relative mb-6">
                <input type="tel" id="exampleInput90" name="tel_name"
                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                    placeholder="tel"/>
                <label
                for="exampleInput90"
                class="absolute text-sm text-neutral-500 transition-all duration-200 ease-out transform -translate-y-4 scale-75 top-0 left-3 origin-[0_0] peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none">
                {line2}
                </label>
                </div>
              <div class="relative mb-6">
                <input type="text" id="exampleInput90" name="nac_name"
                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                    placeholder="nac"/>
                <label
                for="exampleInput90"
                class="absolute text-sm text-neutral-500 transition-all duration-200 ease-out transform -translate-y-4 scale-75 top-0 left-3 origin-[0_0] peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none">
                {line3}
                </label>
                </div>
                <div class="relative mb-6">
                <input type="email" id="exampleInput90" name="user_email"
                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                    placeholder="nac"/>
                <label
                for="exampleInput90"
                class="absolute text-sm text-neutral-500 transition-all duration-200 ease-out transform -translate-y-4 scale-75 top-0 left-3 origin-[0_0] peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none">
                {line4}
                </label>
                </div>
              <div class="relative mb-6" data-te-input-wrapper-init>
                <textarea name="message"
                  class="peer block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  id="exampleFormControlTextarea1" rows="3" placeholder="Your message"></textarea>
                <label for="exampleFormControlTextarea1"
                  class="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none">{line5}</label>
              </div>
              <button type="submit" onSubmit={sendEmail} data-te-ripple-init data-te-ripple-color="light"
                class={`bg-${isSent ? 'green' : 'blue'}-500 mb-6 inline-block w-full rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] lg:mb-0`}>
                  {isSent ? t('contanc.line7') : t('contanc.line6')}
              </button>
            </form>
          </div>
          <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
            <div class="flex flex-wrap">
              <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                <div class="flex items-start">
                  <div class="shrink-0">
                    <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" class="h-6 w-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-6 grow">
                    <p class="mb-2 font-bold">
                      {line8}
                    </p>
                    <p class="text-neutral-500">
                    +4407708316912
                    </p>
                    <p class="text-neutral-500">
                    +34610071424
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                <div class="flex items-start">
                  <div class="shrink-0">
                    <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
                    <MdOutlineAlternateEmail className="h-6 w-6" />
                    </div>
                  </div>
                  <div class="ml-6 grow">
                    <p class="mb-2 font-bold">
                    {line9}
                    </p>
                    <p class="text-neutral-500 ">
                    clarasp.academy@gmail.com
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:mb-12 lg:w-full lg:px-6 xl:w-6/12">
                <div class="align-start flex">
                  <div class="shrink-0">
                    <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
                    <AiFillInstagram className="h-6 w-6" />
                    </div>
                  </div>
                  <div class="ml-6 grow">
                    <p class="mb-2 font-bold ">{line10}</p>
                    <p class="text-neutral-500 ">
                    @clarasp.academy
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
    );
};

export default Form;


