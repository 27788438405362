import S1 from '../assets/S/s1.jpg'
import S2 from '../assets/S/s2.jpeg'
import S3 from '../assets/S/S3.avif'
import {useTranslation} from 'react-i18next';
const Act4 = () => {
    const {t} = useTranslation();
    const {line7,line3,line4,line5} = t("act2");
    return(
      <section className=" bg-blue-500ray-50">
              <div className="py-10  sm:py-16 block lg:py-6 relative bg-opacity-50  z-40  " >     
                  <div className="relative mx-auto h-full px-4 pb-20   md:pb-10 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8"> 
                      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative">
                          <div className="max-w-xl mx-auto text-center">
                              <div className="inline-flex px-4 py-1.5 mx-auto rounded-full  ">
                              </div>
                          </div>
                          <div className="max-w-xl mx-auto text-center">
                              <div className="inline-flex px-4 py-1.5 mx-auto rounded-full  ">
                                  <p className="text-4xl font-semibold tracking-widest text-g text-white">{line7}</p>
                              </div>
                          </div>
                          <div className="grid grid-cols-1 gap-2 mt-12 sm:grid-cols-3 lg:mt-20   ">
                              <a href="#!"
                                  className="transition-all  duration-1000 bg-blue-600 hover:bg-green-600 rounded-br-[80px] hover:shadow-xl m-2 p-4 relative z-40 group  ">
                                  <div
                                      className=" absolute  bg-blue-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  ">
                                  </div>
                                  <div className="py-2 px-9 relative  ">
                                  <img src={S1} alt="" />
                                      <h3 className="mt-8 text-lg font-semibold text-white group-hover:text-black ">{line3}
                                      </h3>                                      
                                  </div>
                              </a>
      
                              <a href="#!"
                                  className="transition-all  duration-1000 bg-purple-600 hover:bg-yellow-600 rounded-br-[80px] hover:shadow-xl m-2 p-4 relative z-40 group  ">
                                  <div
                                      className=" absolute  bg-purple-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  ">
                                  </div>
                                  <div className="py-2 px-9 relative  ">
                                  <img src={S2} alt="" />
                                      <h3 className="mt-8 text-lg font-semibold text-white group-hover:text-black ">{line4}</h3>                                      
                                  </div>
                              </a>
      
                              <a href="#!"
                                  className="transition-all  duration-1000 bg-green-600 hover:bg-blue-500 rounded-br-[80px] hover:shadow-xl m-2 p-4 relative z-40 group  ">
                                  <div
                                      className=" absolute  bg-blue-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  ">
                                  </div>
                                  <div className="py-2 px-9 relative  ">
                                  <img src={S3} alt="" />
                                      <h3 className="mt-8 text-lg font-semibold text-black group-hover:text-white ">{line5}</h3>
                                  </div>
                              </a>
                      </div>
                      </div>
                  </div>
                  </div>
          </section>
          );
};
export default Act4;