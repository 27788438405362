import Footer from '../components/Footer';
import Present from '../components/Present';
import Navbar from '../components/navbar';
const About1 = () => {
    return (
        <>
        <Navbar/>
        <Present/>
        <Footer/>
        </>
    );
};

export default About1;