import R1 from '../assets/R/R1.jpeg';
import R2 from '../assets/R/R2.avif';
import R3 from '../assets/R/R3.avif';
import R4 from '../assets/R/R4.avif';
import R5 from '../assets/R/R5.jpeg';
import R6 from '../assets/R/R6.jpg';
import R7 from '../assets/R/R7.jpg';
import R8 from '../assets/R/R8.jpg';
import {useTranslation} from 'react-i18next';
const Actividades = () =>{
  const {t} = useTranslation();
  const {line1, line2,line3,line4,line5,line6,line7,line8,line9,line10,line11,line12,line13,line14,line15,line16,line17} = t("actividades");
    return (
      <div className="container my-24 mx-auto md:px-6">
        <section className="mb-32 text-center lg:text-left">
        <h3 className="text-5xl text-white font-bold lg:2 mb-4 text-center pb-8 pt-150">{line1}</h3>   
          <div className="grid gap-x-6 lg:grid-cols-3 lg:gap-x-12">
            <div className="mb-6 block rounded-lg bg-blue-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] lg:mb-0" data-te-ripple-init data-te-ripple-color="light">
              <div className="relative overflow-hidden bg-cover bg-no-repeat">
                <img src={R1} className="w-full rounded-t-lg" />
                <a href="#!">
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 h-full w-full bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]"></div>
                </a>
                <svg className="absolute left-0 bottom-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="currentColor"
                    d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                </svg>
              </div>
              <div className="p-6">
                <h5 className="mb-4 text-2xl text-center text-white font-bold">{line2}</h5>
                <p className="mb-6 text-white text-2xl">{line3}
                </p>                
              </div>
            </div>
      
            <div className="mb-6 block rounded-lg bg-green-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] lg:mb-0">
              <div className="relative overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                <img src={R2} className="w-full rounded-t-lg" />
                <a href="#!">
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 h-full w-full bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]"></div>
                </a>
                <svg className="absolute left-0 bottom-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="currentColor"
                    d="M0,96L48,128C96,160,192,224,288,240C384,256,480,224,576,213.3C672,203,768,213,864,202.7C960,192,1056,160,1152,128C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                </svg>
              </div>
              <div className="p-6">
                <h5 className="mb-4 text-2xl font-bold text-center">{line4}</h5>
                <p className="mb-6 text-2xl">{line5}
                </p>
              </div>
            </div>
      
            <div className="block rounded-lg bg-rose-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              <div className="relative overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                <img src={R3} className="w-full rounded-t-lg" />
                <a href="#!">
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 h-full w-full bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]"></div>
                </a>
                <svg className="absolute left-0 bottom-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="currentColor"
                    d="M0,288L48,256C96,224,192,160,288,160C384,160,480,224,576,213.3C672,203,768,117,864,85.3C960,53,1056,75,1152,69.3C1248,64,1344,32,1392,16L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                </svg>
              </div>
              <div className="p-6">
                <h5 className="mb-4 text-2xl text-white text-center font-bold">{line6}</h5>
                <p className="mb-6 text-white text-2xl">{line7}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-32 text-center lg:text-left">
          <div className="grid gap-x-6 lg:grid-cols-3 lg:gap-x-12">
            <div className="mb-6 block rounded-lg bg-red-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] lg:mb-0" data-te-ripple-init data-te-ripple-color="light">
              <div className="relative overflow-hidden bg-cover bg-no-repeat">
                <img src={R4} className="w-full rounded-t-lg" />
                <a href="#!">
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 h-full w-full bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]"></div>
                </a>
                <svg className="absolute left-0 bottom-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="currentColor"
                    d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                </svg>
              </div>
              <div className="p-6">
                <h5 className="mb-4 text-2xl text-center text-white font-bold">{line8}</h5>
                <p className="mb-6 text-white text-2xl">{line9}</p>                
              </div>
            </div>
      
            <div className="mb-6 block rounded-lg bg-yellow-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] lg:mb-0">
              <div className="relative overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                <img src={R5} className="w-full rounded-t-lg" />
                <a href="#!">
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 h-full w-full bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]"></div>
                </a>
                <svg className="absolute left-0 bottom-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="currentColor"
                    d="M0,96L48,128C96,160,192,224,288,240C384,256,480,224,576,213.3C672,203,768,213,864,202.7C960,192,1056,160,1152,128C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                </svg>
              </div>
              <div className="p-6">
                <h5 className="mb-4 text-2xl font-bold text-center">{line10}</h5>
                <p className="mb-6 text-2xl">{line11}
                </p>
              </div>
            </div>
      
            <div className="block rounded-lg bg-purple-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              <div className="relative overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                <img src={R6} className="w-full rounded-t-lg" />
                <a href="#!">
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 h-full w-full bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]"></div>
                </a>
                <svg className="absolute left-0 bottom-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="currentColor"
                    d="M0,288L48,256C96,224,192,160,288,160C384,160,480,224,576,213.3C672,203,768,117,864,85.3C960,53,1056,75,1152,69.3C1248,64,1344,32,1392,16L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                </svg>
              </div>
              <div className="p-6">
                <h5 className="mb-4 text-2xl text-white text-center font-bold">{line12}</h5>
                <p className="mb-6 text-white text-2xl">{line13}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-32 text-center lg:text-left">  
          <div className="grid gap-x-6 lg:grid-cols-3 lg:gap-x-12">
            <div className="mb-6 block rounded-lg bg-blue-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] lg:mb-0" data-te-ripple-init data-te-ripple-color="light">
              <div className="relative overflow-hidden bg-cover bg-no-repeat">
                <img src={R7} className="w-full rounded-t-lg" />
                <a href="#!">
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 h-full w-full bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]"></div>
                </a>
                <svg className="absolute left-0 bottom-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="currentColor"
                    d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                </svg>
              </div>
              <div className="p-6">
                <h5 className="mb-4 text-2xl text-center text-white font-bold">{line14}</h5>
                <p className="mb-6 text-white text-2xl">{line15}
                </p>                
              </div>
            </div>
      
            <div className="mb-6 block rounded-lg bg-green-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] lg:mb-0">
              <div className="relative overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                <img src={R8} className="w-full rounded-t-lg" />
                <a href="#!">
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 h-full w-full bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]"></div>
                </a>
                <svg className="absolute left-0 bottom-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="currentColor"
                    d="M0,96L48,128C96,160,192,224,288,240C384,256,480,224,576,213.3C672,203,768,213,864,202.7C960,192,1056,160,1152,128C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                  </path>
                </svg>
              </div>
              <div className="p-6">
                <h5 className="mb-4 text-2xl font-bold text-center">{line16}</h5>
                <p className="mb-6 text-2xl">{line17}
                </p>
              </div>
            </div>
          </div>
        </section>
        
      </div>
    );
};
export default Actividades;
