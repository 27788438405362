import banner from '../assets/banner.jpg';
import Banner from '../shared/Banner';
import {useTranslation} from 'react-i18next';
const Home = () => {
    const {t} = useTranslation();
    return (
        <div className="md:px-12 p-4 max-w-screen-2xl mx-auto mt-24" id='home'>
            <Banner banner={banner} heading={t('home.line1')} subheading={t('home.line2')} btn1={'Get started'} btn2={'discount'}/>
        </div>
    );
};

export default Home;