import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    fallbacklng: 'en',
    returnObjects: true,
    resources: {
        es: {
            translation: {
                navbar:{
                    line1: "Inicio",
                    line2: "Sobre mi",
                    line3: "Clases",
                    line4: "Blog",
                    line5: "Contacto"
                },
                home:{
                    line1: "¡Hola y bienvenido a MISS. Clara Spanish Academy ! ",
                    line2: "Estamos encantados de que hayas decidido emprender el emocionante viaje de aprender español con nosotros.En nuestra academia, nos apasiona compartir el idioma y la cultura española con niños de 5 a 12 años, brindando un entorno seguro, divertido y estimulante para su desarrollo lingüístico y cultural. Con nuestro enfoque lúdico y creativo, cada clase es una oportunidad para sumergirse en el idioma a través de juegos interactivos, canciones pegajosas y actividades prácticas que hacen que el aprendizaje sea tan emocionante como jugar. ",
                },
                features:{
                    line1:"¿Por qué elegirnos?",
                    line2:"En MISS Clara, no solo enseñamos el idioma, sino que también nutrimos la curiosidad, la creatividad y el espíritu de descubrimiento de cada niño.¡Únete a nuestra comunidad y permite que tu hijo se sumerja en un mundo de aprendizaje vibrante y lleno de diversión con Miss Clara!.",
                    line3:"Profesores Nativos",
                    line4:"Nuestro equipo de profesores nativos te guiará a través de actividades interactivas que te permitirán aprender de manera práctica y efectiva .",
                    line5:"Recursos Multimedia Envolventes",
                    line6:"Sumérgete en nuestro contenido multimedia, que incluye videos, música, y material auténtico para ayudarte a comprender el español en su contexto general .",
                    line7:"Clases Personalizadas",
                    line8:"Adaptamos nuestro enfoque a tus necesidades y niveles de habilidad individuales, proporcionando una experiencia de aprendizaje única! .",
                    line9:"Nuestra Comunidad Aprende y Crece Juntos",
                    line10:"En nuestra academia además de estar aprendiendo formarás parte de una comunidad en crecimiento.Conéctate con otros estudiantes, participa en eventos culturales y practica tus habilidades lingüísticas en un entorno creativo .",
                    line11:"Comienza Tu Viaje Hoy",
                    line12:"No importa si eres principiante o si ya tienes algunas bases en español. En MISS Clara Academy, encontrarás el camino perfecto para alcanzar tus metas lingüísticas.¡Empieza tu viaje hoy mismo y descubre el placer de hablar español! ."
                },
                present: {
                    line1: "¡Hola! Soy MISS. Clara, una apasionada profesora con más de 10 años de experiencia en el emocionante mundo de la educación infantil. Mi misión es convertir el aprendizaje del español en una aventura tan emocionante como una búsqueda de tesoros .",
                    line2: "Utilizando herramientas innovadoras con un toque de magia .",
                    line3: "En MISS. Clara, nos sumergimos en un mundo de juegos, canciones, cuentos y actividades creativas, donde cada paso es una nueva aventura y cada palabra es un tesoro por descubrir. ¡Vamos a hacer de cada clase un viaje inolvidable! .",
                    line4: "¡Prepárense para sumergirse en un océano de diversión y descubrimiento! Estoy aquí para acompañarlos en cada paso del camino, ¡así que vamos juntos a conquistar el español con una sonrisa en el rostro! .",
                    line5: "¿Quien es MISS. Clara Spanish Online Academy?"
                },
                actividades:{
                    line1:"Actividades Recreativas",
                    line2:"Rutinas Diarias",
                    line3:"Rutina en español, donde los estudiantes registren actividades cotidianas, practicando la conjugación de verbos regulares e irregulares en presente .",
                    line4:"Clima y vestimenta",
                    line5:"Utilizar pronósticos del tiempo reales de países de habla hispana para discutir el clima y las actividades adecuadas, así como la ropa apropiada para diferentes condiciones climáticas .",
                    line6:"Viaje Virtual por Hispanoamérica",
                    line7:"Aprender sobre la cultura, la geografía, las tradiciones, y probar palabras y frases típicas de cada lugar .",
                    line8:"Cocinando en Español",
                    line9:"Los niños aprenden a preparar recetas simples de la cocina hispana, practicando vocabulario de ingredientes, utensilios en español .",
                    line10:"Arte y Creatividad en Español",
                    line11:"Proyectos de arte inspirados en artistas españoles.Los niños aprenden sobre su vida, su obra y crean su propio arte .",
                    line12:"Tesoros de la Literatura Infantil Española",
                    line13:"Lectura y análisis de cuentos infantiles clásicos y modernos de autores hispanohablantes, enfocándose en la moral de las historias y el vocabulario clave .",
                    line14:"Historias de Exploradores y Aventureros",
                    line15:"Aventuras y leyendas de tesoros escondidos. Los niños aprenden historia de manera emocionante y misteriosa .",
                    line16:"Deportes y Atletas",
                    line17:"Conocer sobre deportes populares españoles y biografías de atletas destacados ."
                },
                act2:{
                    line1: "Apoyo",
                    line2: "Niños de 5-7 años",
                    line3: "Canciones Infantiles",
                    line4: "Musicas Infantiles",
                    line5: "Clases Personalizadas",
                    line6: "Niños de 8-10 años",
                    line7: "Niños de 11-12 años",
                },   
                test:{
                    line1: "Testimonios",
                    line2: "Clases Personalizadas",
                    line3: "Desde que mis hijos comenzaron a asistir a las clases de español en MISS. Clara Spanish Academy, su interés y habilidad por el idioma han mejorado notablemente. Lo que más me gusta es ver cómo aprenden jugando, lo cual hace que cada clase sea una aventura para ellos. Sin duda, es una actividad que recomiendo a todos los padres interesados en enriquecer la educación lingüística de sus hijos de una manera divertida y efectiva",
                    line4: "Curso Intermedio",
                    line5: "Estoy realmente impresionada con el método interactivo que utiliza MISS. Clara Spanish Academy. Mi hijo siempre ha sido algo reacio a las clases de idiomas, pero las actividades lúdicas y el entorno interactivo han transformado completamente su actitud hacia el aprendizaje del español. Es maravilloso ver su progreso. Recomiendo encarecidamente esta academia a cualquier padre que busque una experiencia educativa de alta calidad para sus hijos",
                    line6: "Curso Basico",
                    line7: "Quiero expresar mi más profundo agradecimiento a MISS. Clara Spanish Academy por ofrecer un entorno de aprendizaje tan estimulante y eficaz. La combinación de juegos, actividades interactivas y un enfoque lingüístico bien estructurado ha sido clave en el desarrollo lingüístico de mi hija. Como padre, es reconfortante verla emocionada por aprender más después de cada clase. Definitivamente, es una inversión que vale la pena",
                },
                Pricing:{
                    line1:"Precios",
                    line2:"Elige el precio que mas se adecue a ti ",
                    line3:"Precios por clases",
                    line4:"15£",
                    line5:"Comprar",
                    line6:"Si pagas más de 2 clases: 14 £ cada una",
                    line7:"inicio",
                    line8:"50£ X mes",
                    line9:"una clase por semana",
                    line10:"Intermedio",
                    line11:"100£ X mes",
                    line12:"dos clases por semana",
                    line13:"Promoción",
                    line14:"120£ X mes",
                    line15:"10 clases (ideal para viajar a Barcelona)",
                    line16:"incluye material turístico e información para tu viaje a barcelona",
                    line17:"Curso intensivo",
                    line18:"250£ X mes",
                    line19:"5 Dias a la semana"
                },
                footer:{
                    line1: "Animate",
                    line2: "A conocer",
                    line3: "Nuestra Escuela",
                    line4: "Correo",
            },
            contanc:{
                line1: "Nombre",
                line2: "Telefono",
                line3: "Nacionalidad",
                line4: "Correo electronico",
                line5: "Mensaje",
                line6: "Enviar",
                line7: "Enviado",
                line8: "Telefonos",
                line9: "Correo",
                line10: "Redes sociales",
        },
        }
        },
        en: {
            translation: {
                navbar:{
                    line1: "Home",
                    line2: "About me",
                    line3: "Classes",
                    line4: "Blog",
                    line5: "Contact"
                },
                home:{
                    line1: "Hello and welcome to MISS. Clara Spanish Academy !",
                    line2: "We are delighted that you have decided to embark on the exciting journey of learning Spanish with us. At our academy, we are passionate about sharing the Spanish language and culture with children from 5 to 12 years old, providing a safe, fun and stimulating environment for their linguistic and cultural development. With our playful and creative approach, every class is an opportunity to immerse yourself in the language through interactive games, catchy songs and hands-on activities that make learning as exciting as playing.",
                },
                features:{
                    line1:"Why choose us?",
                    line2:"At MISS Clara, we not only teach language, but also nurture each child's curiosity, creativity and spirit of discovery. Join our community and allow your child to immerse themselves in a vibrant and fun-filled world of learning with Miss Clara!. ",
                    line3:"Native Teachers",
                    line4:"Our team of native teachers will guide you through interactive activities that will allow you to learn in a practical and effective way.",
                    line5:"Surround Multimedia Resources",
                    line6:"Immerse yourself in our multimedia content, which includes videos, music, and authentic material to help you understand Spanish in its general context.",
                    line7:"Customized Classes",
                    line8:"We tailor our approach to your individual needs and skill levels, providing a unique learning experience!",
                    line9:"Our Community Learns and Grows Together",
                    line10:"In addition to learning, you will be part of a growing community, connect with other students, participate in cultural events and practice your language skills in a creative environment.",
                    line11:"Start Your Trip Today",
                    line12:"It doesn't matter if you are a beginner or if you already have some basics in Spanish. At MISS Clara Academy, you will find the perfect way to reach your language goals, start your journey today and discover the pleasure of speaking Spanish!"
                },
                present: {
                    line1: "Hello! I'm MISS. Clara, a passionate teacher with more than 10 years of experience in the exciting world of early childhood education. My mission is to turn learning Spanish into an adventure as exciting as a treasure hunt.",
                    line2: "Using innovative tools with a touch of magic.",
                    line3: "At MISS. Clara, we immerse ourselves in a world of games, songs, stories and creative activities, where every step is a new adventure and every word is a treasure to discover. Let's make every class an unforgettable journey!",
                    line4: "Get ready to dive into an ocean of fun and discovery! I'm here to accompany you every step of the way, so let's go together to conquer Spanish with a smile on our faces!",
                    line5: "Who is MISS Clara Academia de Español Online?"
                },
                actividades:{
                    line1:"Recreational Activities",
                    line2:"Daily Routines",
                    line3:"Routine in Spanish, where students record daily activities, practicing the conjugation of regular and irregular verbs in the present tense.",
                    line4:"Climate and clothing",
                    line5:"Use actual weather forecasts from Spanish-speaking countries to discuss appropriate weather and activities, as well as appropriate clothing for different weather conditions.",
                    line6:"Virtual Trip through Latin America",
                    line7:"Learn about the culture, geography, traditions, and try typical words and phrases of each place.",
                    line8:"Cooking in Spanish",
                    line9:"Children learn to prepare simple Hispanic recipes, practicing vocabulary of ingredients and utensils in Spanish.",
                    line10:"Art and Creativity in Spanish",
                    line11:"Art projects inspired by Spanish artists, children learn about their lives, their work and create their own art.",
                    line12:"Treasures of Spanish Children's Literature",
                    line13:"Reading and analysis of classic and modern children's stories by Spanish-speaking authors, focusing on story morals and key vocabulary.",
                    line14:"Explorers and Adventurers Stories",
                    line15:"Adventures and legends of hidden treasures. Children learn history in an exciting and mysterious way.",
                    line16:"Sports and Athletes",
                    line17:"To learn about popular Spanish sports and biographies of outstanding athletes."
                },
                act2:{
                    line1: "Support",
                    line2: "Children 5-7 years old",
                    line3: "Children's Songs",
                    line4: "Children's Music",
                    line5: "Customized Classes",
                    line6: "Children 8-10 years old",
                    line7: "Children 11-12 years old",
                },
                test:{
                    line1: "Testimonials",
                    line2: "Customized Classes",
                    line3: "Since my children started attending Spanish classes at MISS. Clara Spanish Academy, their interest and ability in the language has improved dramatically. What I like most is to see how they learn by playing, which makes each class an adventure for them. Without a doubt, it is an activity that I recommend to all parents interested in enriching their children's language education in a fun and effective way.",
                    line4: "Intermediate Course",
                    line5: "I am really impressed with the interactive method MISS. Clara Spanish Academy uses. My son has always been somewhat reluctant to take language classes, but the playful activities and interactive environment have completely transformed his attitude towards learning Spanish. It is wonderful to see his progress. I highly recommend this academy to any parent looking for a high quality educational experience for their children.",
                    line6: "Basic Course",
                    line7: "I would like to express my deepest appreciation to MISS. Clara Spanish Academy for providing such a stimulating and effective learning environment. The combination of games, interactive activities and a well-structured language approach has been key in my daughter's linguistic development. As a parent, it is refreshing to see her excited to learn more after each class. Definitely a worthwhile investment.",
                },
                Pricing:{
                    line1:"Prices",
                    line2:"Choose the price that best suits your needs ",
                    line3:"Prices per class",
                    line4:"15£",
                    line5:"Buy",
                    line6:"If you pay for more than 2 lessons: £14 each",
                    line7:"start",
                    line8:"50£ X month",
                    line9:"one class per week",
                    line10:"Intermediate",
                    line11:"100£ X month",
                    line12:"two classes per week",
                    line13:"Promotion",
                    line14:"120£ X month",
                    line15:"10 classes (ideal for travel to Barcelona)",
                    line16:"includes tourist material and information for your trip to barcelona",
                    line17:"Intensive course",
                    line18:"250£ X month",
                    line19:"5 days a week"
                },
                footer:{
                    line1: "Come",
                    line2: "and visit",
                    line3: "our school",
                    line4: "e-mail",
            },
            contanc:{
                line1: "Name",
                line2: "Phone",
                line3: "Nationality",
                line4: "E-mail address",
                line5: "message",
                line6: "Send to",
                line7: "Sent to",
                line8: "Phones",
                line9: "E-mail",
                line10: "Social networks",
        },                       
            }
        }
    }
});

export default i18n;

