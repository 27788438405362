import { useTranslation } from "react-i18next";

const languages = [
    {code: "en", lang: "English"},
    {code: "es", lang: "Español"},
];

const LanguageSelector = () =>{
    const {i18n} = useTranslation();
    const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    };
    return (
        <div className="mx-4 space-x-2 br-2 cursor-pointer flex flex-col md:flex-row items-center">
            {languages.map((lng) =>{
                return <button className={lng.code === i18n.language? "selected" : ""} key={lng.code} onClick={()=>changeLanguage(lng.code)}>{lng.lang}</button>
            })}
        </div>
     )
};

export default LanguageSelector;