import './App.css';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Home1 from './pages/home';
import About1 from './pages/about';
import Clases from './pages/clases';
import Contac from './pages/Contac';
import Blog from './pages/blog';

function App() {
  return (
    <>
    <Router>
      <Routes path="/" element={<Home1 />} >
        <Route path="/" element={<Home1 />} />
        <Route path="/home" element={<Home1 />} />
        <Route path="/about" element={<About1 />} />
        <Route path="/pricing" element={<Clases />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contacto" element={<Contac />} />
        <Route path="*" element={<Home1 />} />
      </Routes>
    </Router>
    </>

  );
}

export default App;
