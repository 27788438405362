import {useTranslation} from 'react-i18next';
const Testimonials = () =>{
  const {t} = useTranslation();
  const {line1, line2,line3,line4,line5,line6,line7} = t("test");
return(
    <div className="container my-24 mx-auto md:px-6">
      <section className="mb-32 text-center">
      <h3 className="text-5xl text-white font-bold lg:2 mb-4 text-center pb-8 pt-150">{line1}</h3>    
        <div className="grid gap-6 md:grid-cols-3 xl:gap-x-12">
          <div className="mb-6 lg:mb-0">
            <div
              className="relative block rounded-lg bg-yellow-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              <div className="flex">
                <div
                  className="relative mx-4 -mt-4 w-full overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
                  data-te-ripple-init data-te-ripple-color="light">
                  <a href="#!">
                    <div
                      className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                    </div>
                  </a>
                </div>
              </div>
              <div className="p-6">
                <h5 className="mb-2 text-lg font-bold text-white">Alice</h5>
                <h6 className="mb-4 font-medium text-white">
                {line2}
                </h6>
                <p className="text-white">
                {line3}
                </p>
              </div>
            </div>
          </div>
    
          <div className="mb-6 lg:mb-0">
            <div
              className="relative block rounded-lg bg-purple-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              <div className="flex">
                <div
                  className="relative mx-4 -mt-4 w-full overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
                  data-te-ripple-init data-te-ripple-color="light">
                  <a href="#!">
                    <div
                      className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                    </div>
                  </a>
                </div>
              </div>
              <div className="p-6">
                <h5 className="mb-2 text-lg font-bold text-white">Dom</h5>
                <h6 className="mb-4 font-medium text-white">
                {line4}
                </h6>                
                <p className="text-white">
                {line5}
                </p>
              </div>
            </div>
          </div>
    
          <div className="mb-0">
            <div
              className="relative block rounded-lg bg-red-600 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
              <div className="flex">
                <div
                  className="relative mx-4 -mt-4 w-full overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
                  data-te-ripple-init data-te-ripple-color="light">
                  <a href="#!">
                    <div
                      className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                    </div>
                  </a>
                </div>
              </div>
              <div className="p-6">
                <h5 className="mb-2 text-lg font-bold text-white">Ángel</h5>
                <h6 className="mb-4 font-medium text-white">
                {line6}
                </h6>               
                <p className=" text-white">
                {line7}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    );
};
export default Testimonials;