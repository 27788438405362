import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import FeedInsta from "../components/feedinsta";
import Navbar from "../components/navbar";

const Blog = () => {
    return (
        <>
        <Navbar/>
        <Testimonials/>
        <FeedInsta/>
        <Footer/>
        </>
    );
};

export default Blog;