import Footer from '../components/Footer';
import Pricing from '../components/Pricing';
import Navbar from '../components/navbar';

const Clases = () => {
    return (
        <>
        <Navbar/>
        <Pricing/>
        <Footer/>
        </>
    );
};

export default Clases;