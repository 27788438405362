import logo from '../assets/logo.png';
import {useTranslation} from 'react-i18next';
const Footer = () =>{
  const {t} = useTranslation();
  const {line1, line2,line3,line4} = t("footer");
return(
  <>
  <section class="bg-white">
         <div
              class="max-w-lg bg-neutral-300 px-4 pt-24 py-8 mx-auto text-left md:max-w-none md:text-center"
            >
              <h1
                class="text-3xl font-extrabold leading-10 tracking-tight text-left text-white text-center sm:leading-none md:text-6xl text-4xl lg:text-7xl"
              >
                <span
                  class=" mt-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-emerald-400 to-green-500 md:inline-block"
                > {line1} <span class="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 via-cyon-400 to-purple-300">{line2} </span> </span>&nbsp;
                <span
                  class=" mt-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-emerald-400 to-green-500 md:inline-block"
                ><span class="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 via-cyon-400 to-purple-300">{line3} </span> </span>
              </h1>
              <div
                class="mx-auto rounded-lg font-black mt-5 text-zinc-400 md:mt-12 md:max-w-lg text-center lg:text-lg">
              </div>
            </div>
    </section>
    
  <hr class="text-white mx-5" />
    <footer class="bg-neutral-300 pb-5">
    <div class="max-w-screen-xl px-4 pt-8 mx-auto sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center sm:justify-between">
        <div class="flex justify-center text-teal-300 sm:justify-start">
       <img class="rounded-full" src={logo}  width="40" height="40" />
        </div>
  
        <div class="mt-4 text-sm text-center text-gray-400 lg:text-right lg:mt-0">
          <a href="">Instagram</a> &nbsp;
          <a href="https://github.com/RubenSasaki">Git Hub</a> &nbsp;
        </div>
      </div>
    </div>
  </footer>
  </>
);
};
 export default Footer;